import React, { useState } from 'react'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import FormView from './Form.view'
import { managePaymentTransactionSchema } from 'Validation'
import axios from 'Utils/axiosRexyAdmin'

const initialValues = {
  date: moment(),
  customer_id: undefined,
  sale_order_id: undefined,
  payment_method: undefined,
  amount: undefined,
  note: '',
  total_amount: 0,
  paid_amount: 0,
  not_paid_amount: 0,
}

export default React.memo((props) => {
  const { path, editId } = props

  const history = useHistory()
  const [serverError, setServerError] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleFormSubmit = async (values, actions) => {
    setServerError(null)
    setIsSubmitting(true)
    if (path === 'new') {
      createPaymentTransaction(
        { ...values, date: moment(values.date).format('YYYY-MM-DD') },
        actions
      )
    } else {
      updatePaymentTransaction(
        { ...values, date: moment(values.date).format('YYYY-MM-DD') },
        actions
      )
    }
  }

  const createPaymentTransaction = async (params, actions) => {
    const url = '/payment_transactions/'
    try {
      const resp = await axios.post(url, params)
      setIsSubmitting(false)
      history.push(`/payment-transactions/${resp.data.id}/view`)
    } catch (error) {
      setServerError(error.response?.data.errors?.message)
      setIsSubmitting(false)
    }
  }
  const updatePaymentTransaction = async (params, actions) => {
    const url = `/payment_transactions/${editId}/edit/`

    try {
      const resp = await axios.put(url, { ...params })
      setIsSubmitting(false)
      history.push(`/payment-transactions/${resp.data.id}/view`)
    } catch (error) {
      setServerError(error.response?.data.errors?.message)
      setIsSubmitting(false)
    }
  }

  return (
    <div className="payment-transaction-form">
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        children={(props) => (
          <FormView
            {...props}
            serverError={serverError}
            editId={editId}
            path={path}
            isSubmitting={isSubmitting}
          />
        )}
        validationSchema={managePaymentTransactionSchema}
      />
    </div>
  )
})
