import React, { useState, memo } from 'react'
import { Checkbox, Input, InputNumber, message } from 'antd'
import { Field } from 'formik'

import { formatInputNumber, formatNumber, formatToNumber } from 'Utils'

const ProducingItem = ({
  loadingCondition,
  isSuperAdmin,
  focusingRow,
  index,
  item,
  values,
  dataFetch,
  setFocus,
  setFieldValue,
}) => {
  const [maximumProducedBoxQuantity] = useState(() => {
    const canProduce =
      formatToNumber(dataFetch.sale_order_items[index].produced_box_quantity) +
      formatToNumber(dataFetch.sale_order_items[index].actual_produce_quantity)

    return canProduce < item.box_quantity ? canProduce : item.box_quantity
  })

  const handleProducedChange = (fieldName, limit, value, doneField) => {
    if (value > limit) {
      message.warn(item.produce_message || 'Vui lòng kiểm tra tồn kho')
      return
    }
    if (typeof value !== 'number') {
      return
    }
    if (!value && value !== 0) {
      return setFieldValue(fieldName, undefined)
    }

    const roundDown = Math.floor(value)
    setFieldValue(fieldName, Number(roundDown))
    if (value < limit) {
      setFieldValue(doneField, false)
    }
    if (value === limit) {
      setFieldValue(doneField, true)
    }
  }

  const handleChangeDone = (fieldName, event, producedField, producedValue) => {
    const checked = event.target.checked
    if (maximumProducedBoxQuantity < item.box_quantity) {
      message.warn(item.produce_message || 'Vui lòng kiểm tra tồn kho')
      return
    }
    setFieldValue(fieldName, checked)
    if (checked) {
      setFieldValue(producedField, producedValue)
    }
  }

  const handleRowColor = () => {
    if (focusingRow === index) return
    setFocus(index)
  }

  return (
    <div key={index} className={`row-wrapper ${focusingRow === index ? 'is-focusing' : ''}`}>
      <div className="row-item" style={{ maxWidth: 60 }}>
        {index + 1}
      </div>
      <div className="row-item">{`${item.product.code}: ${item.product.name}`}</div>
      <div style={{ maxWidth: 160 }} className="row-item">
        {item.specification}
      </div>
      <div className="row-item" style={{ maxWidth: 160 }}>
        {item.product_type === 'semifinish'
          ? item.first_price
            ? formatNumber(item.first_price)
            : '-'
          : '-'}
      </div>
      {isSuperAdmin && (
        <div className="row-item" style={{ maxWidth: 160 }}>
          {item.product_type === 'finish'
            ? item.second_price
              ? formatNumber(item.second_price)
              : '-'
            : '-'}
        </div>
      )}
      {isSuperAdmin && (
        <div className="row-item" style={{ maxWidth: 140 }}>
          {formatNumber(Number(item.box_quantity))}
        </div>
      )}
      <div className="row-item" style={{ maxWidth: 140 }}>
        {formatNumber(Number(item.total_quantity))}
      </div>
      <div className="row-item" style={{ maxWidth: 120 }}>
        {formatNumber(Number(item.total_size))}
      </div>
      <div style={{ maxWidth: 140 }} className="row-item">
        {item.note}
      </div>

      {isSuperAdmin ? (
        <>
          <div style={{ maxWidth: 120 }} className="row-item">
            {formatNumber(Number(item.old_fob))}
          </div>
          <div style={{ maxWidth: 120 }} className="row-item">
            {formatNumber(Number(item.new_fob))}
          </div>
          <div style={{ maxWidth: 120 }} className="row-item">
            {formatNumber(Number((item.new_fob * item.box_quantity).toFixed(2)))}
          </div>
        </>
      ) : null}

      <div className="row-item" style={{ maxWidth: 190 }}>
        <Input.Group compact>
          <Field
            as={InputNumber}
            name={`sale_order_items[${index}].produced_box_quantity`}
            disabled={
              loadingCondition
              // loadingCondition || item.box_quantity === item.produced_box_quantity
            }
            // disabled={loadingCondition item.product_type=== "finish"}
            onFocus={handleRowColor}
            value={values.sale_order_items[index].produced_box_quantity}
            onChange={(value) =>
              handleProducedChange(
                `sale_order_items[${index}].produced_box_quantity`,
                maximumProducedBoxQuantity,
                value,
                `sale_order_items[${index}].completed`
              )
            }
            style={{ width: '60%' }}
            formatter={(value) => formatInputNumber(value)}
            placeholder="Số lượng đã sản xuất"
            min={dataFetch.sale_order_items[index].produced_box_quantity}
            max={item.box_quantity}
          />
          <Input
            className="unit-disable-field"
            disabled={true}
            style={{
              width: '40%',
              backgroundColor: '#FCFCFC',
              color: '#474747',
              textAlign: 'center',
            }}
            value={`/ ${item.box_quantity}`}
          />
        </Input.Group>
      </div>

      <div className="row-item" style={{ alignItems: 'center', maxWidth: 100 }}>
        <Field
          name={`sale_order_items[${index}].completed`}
          as={Checkbox}
          disabled={
            loadingCondition
            // loadingCondition || item.box_quantity === item.produced_box_quantity
          }
          checked={values.sale_order_items[index].completed}
          onChange={(event) =>
            handleChangeDone(
              `sale_order_items[${index}].completed`,
              event,
              `sale_order_items[${index}].produced_box_quantity`,
              item.box_quantity
            )
          }
        />
      </div>
    </div>
  )
}

export default memo(ProducingItem)
