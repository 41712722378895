import React, { useState, useEffect } from 'react'
import { Typography, Col, Button, message } from 'antd'
import { RollbackOutlined, FormOutlined, ToolOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { DocumentsTable } from 'Components'

import MaterialPriceModal from './MaterialPriceModal'
// import axiosExpand from 'Utils/axiosExpand'
import get from 'lodash/get'
import map from 'lodash/map'
import size from 'lodash/size'

import { formatNumber, convertObjectToQueryString, roundUpDecimal, formatToNumber } from 'Utils'
import { OtherDocumentsTable } from 'Components'
import axios from 'Utils/axiosRexyAdmin'

const { Title } = Typography

const format = 'DD-MM-YYYY'

const modalInitialValues = {
  sale_order_id: undefined,
  sale_order_materials: [
    {
      material_id: undefined,
      material_name: '',
      price: undefined,
      use_new_price: false,
    },
  ],
}

const Suppliers = (props) => {
  const { data, viewId, loadingCondition } = props

  return map(data.suppliers, (supplier) => (
    <Supplier
      data={data}
      viewId={viewId}
      supplier={supplier}
      key={get(supplier, 'code')}
      loadingCondition={loadingCondition}
    />
  ))
}

const Supplier = React.memo(({ supplier, data, viewId, loadingCondition }) => {
  // const history = useHistory()
  const supplierCode = get(supplier, 'code')
  const supplierName = get(supplier, 'name')
  const supplierId = get(supplier, 'id')
  const supplierCodeAndName = `${supplierCode}: ${supplierName}`

  const getRow = (purchaseOrderType) => {
    if (loadingCondition)
      return (
        <div className={getRowClassName(purchaseOrderType)}>{getRowText(purchaseOrderType)}</div>
      )
    return (
      <a
        className={getRowClassName(purchaseOrderType)}
        href={getRowOnClick(purchaseOrderType)}
        rel="noopener noreferrer"
        target="_blank"
      >
        {getRowText(purchaseOrderType)}
      </a>
    )
  }

  const getRowClassName = (purchaseOrderType) => {
    return get(supplier, `supply.${purchaseOrderType}`)
      ? 'purchaseable-button'
      : 'unpurchaseable-button'
  }

  const getRowText = (purchaseOrderType) => {
    const readablePurchaseOrderType =
      purchaseOrderType === 'packing'
        ? 'bao bì'
        : purchaseOrderType === 'material'
        ? 'nguyên vật liệu'
        : purchaseOrderType === 'label'
        ? 'tem'
        : 'thành phẩm'

    return get(supplier, `supply.${purchaseOrderType}`)
      ? 'Đặt hàng'
      : `Nhà cung cấp này không cung cấp bất kì loại ${readablePurchaseOrderType} nào trong order hiện tại`
  }

  const getRowOnClick = (purchaseOrderType) => {
    if (get(supplier, `supply.${purchaseOrderType}`)) {
      const queryString = {
        sale_order_id: viewId || '',
        order_number: data.order_number || '',
        supplier_id: supplierId || '',
        supplier_code: supplierCode || '',
        order_type: purchaseOrderType || '',
      }
      const converted = convertObjectToQueryString(queryString)
      return `/purchase-orders/new?${converted}`
    }
  }

  return (
    <div className="row-wrapper">
      <div className="row-item" style={{ maxWidth: 230 }}>
        {supplierCodeAndName}
      </div>
      <div className="row-item" style={{ maxWidth: 230 }}>
        {getRow('packing')}
      </div>
      <div className="row-item" style={{ maxWidth: 230 }}>
        {getRow('material')}
      </div>
      <div className="row-item" style={{ maxWidth: 230 }}>
        {getRow('label')}
      </div>
      <div className="last-child" style={{ maxWidth: 230 }}>
        {getRow('finish_product')}
      </div>
    </div>
  )
})

export default React.memo((props) => {
  const { data, loading, viewId, totalCubic, presentCubic, isSuperAdmin } = props
  const history = useHistory()

  const [editProductMaterial, setEditProductMaterial] = useState({
    data: modalInitialValues,
    loading: true,
    visible: false,
  })

  const [isSubmitting, setSubmitting] = useState(false)
  const [totalData, setTotalData] = useState({
    total_box_quantity: 0,
    total_quantity: 0,
    total_size: 0,
    total_money: 0,
  })

  useEffect(() => {
    let didCancel = false

    const calTotal = () => {
      let total_box_quantity = 0
      let total_quantity = 0
      let total_size = 0
      let total_money = 0

      data.sale_order_items.forEach((item) => {
        total_box_quantity += formatToNumber(item.box_quantity)
        total_quantity += formatToNumber(item.total_quantity)
        total_size += formatToNumber(item.total_size)
        total_money += formatToNumber(item.new_fob * item.box_quantity)
      })

      setTotalData({
        total_box_quantity,
        total_quantity,
        total_size,
        total_money,
      })
    }

    if (!didCancel) {
      calTotal()
    }

    return () => (didCancel = true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.sale_order_items])

  const handleBack = () => {
    !!history.goBack && typeof history.goBack === 'function'
      ? history.goBack()
      : history.push(`/sale-orders`)
  }

  const handleEdit = () => {
    if (data.status === 'drafting') {
      history.push(`/sale-orders/${viewId}/edit`)
    } else {
      history.push(`/sale-orders/${viewId}/proformal-invoice`)
    }
  }

  const handlePI = () => {
    history.push(`/sale-orders/${viewId}/proformal-invoice`)
  }

  const generateSuggestion = () => {
    if (!totalCubic || !presentCubic) {
      return ''
    } else if (totalCubic > presentCubic) {
      return (
        <span style={{ color: 'green' }}>
          {`Thiếu ${(totalCubic - presentCubic).toFixed(2)} m3`}
        </span>
      )
    } else if (totalCubic < presentCubic) {
      return (
        <span style={{ color: 'red' }}>{`Thừa ${(presentCubic - totalCubic).toFixed(2)} m3`}</span>
      )
    } else return ''
  }

  const updatePurchaseOrder = async (status) => {
    setSubmitting(true)
    const url = `/sale_orders/${viewId}/edit/`
    const formattedDocumentsParam = map(data.documents, (document) => ({
      file_name: get(document, 'originFileObj.name'),
      aws_object_key: get(document, 'originFileObj.object_key'),
    }))

    const formatParams = {
      ...data,
      documents: formattedDocumentsParam,
      status,
    }

    try {
      await axios.put(url, formatParams)
      window.location.reload()
    } catch (error) {
      message.error('Chưa đủ điều kiện để sản xuất')
      setSubmitting(false)
    }
  }

  const isPendingPIOrApprovedPIAndIsSuperAdmin =
    (data.status === 'pending_pi' || data.status === 'approved_pi') && isSuperAdmin
  const isDrafting = data.status === 'drafting'
  const isPendingPI = data.status === 'pending_pi'
  const isApprovedPI = data.status === 'approved_pi'

  const loadingCondition = isSubmitting || loading

  return (
    <div id="view_sale_order_drafting_pending" className="table-container">
      <div className="inner-view">
        <div className="header-view">
          <Title className="page-title">Thông tin đơn hàng</Title>
        </div>

        <div className="view-wrapper">
          <div className="form-item">
            <Title className="field-title">Order No:</Title>
            <div className="content">{data.order_number}</div>
          </div>

          <div className="form-item">
            <Title className="field-title">Ngày:</Title>
            <div className="content">{moment(data.date, 'YYYY-MM-DD').format(format)}</div>
          </div>

          <div className="form-item">
            <Title className="field-title">Khách hàng:</Title>
            <div className="content">
              {data.customer.code}: {data.customer.name}
            </div>
          </div>

          <div className="total-wrapper">
            <div className="requirement">
              <Title className="requirement-title">Điều kiện sản xuất lô hàng:</Title>
              <span className="requirement-content" style={{ wordBreak: 'break-word' }}>
                {data.production_requirement ? data.production_requirement : '-'}
              </span>
            </div>
          </div>

          <div className="order-table">
            <Title className="order-table-title">Thông tin sản phẩm:</Title>
            <Col className="order-table-content">
              <div className="inner-content">
                <div
                  className="scroll-wrap"
                  style={{ minWidth: isPendingPIOrApprovedPIAndIsSuperAdmin ? 1470 : 1120 }}
                >
                  <div className="row-wrapper">
                    <div className="row-item thread-row" style={{ maxWidth: 60 }}>
                      STT
                    </div>
                    <div className="row-item thread-row" style={{ minWidth: 250 }}>
                      Tên sản phẩm
                    </div>
                    <div style={{ maxWidth: 190 }} className="row-item thread-row">
                      Packing
                    </div>
                    {/* <div className="row-item thread-row">Nguyên vật liệu</div> */}
                    {isDrafting && (
                      <>
                        <div className="row-item thread-row" style={{ maxWidth: 120 }}>
                          Giá mua sản phẩm chưa thành phẩm(Kg)
                        </div>
                        <div className="row-item thread-row" style={{ maxWidth: 120 }}>
                          Giá mua sản phẩm thành phẩm(Thùng)
                        </div>
                      </>
                    )}
                    <div className="row-item thread-row" style={{ maxWidth: 120 }}>
                      Số lượng (thùng)
                    </div>
                    <div className="row-item thread-row" style={{ maxWidth: 120 }}>
                      Số lượng(đơn vị)
                    </div>
                    <div className="row-item thread-row" style={{ maxWidth: 150 }}>
                      Cubic (m3)
                    </div>
                    {isPendingPIOrApprovedPIAndIsSuperAdmin ? (
                      <>
                        <div style={{ maxWidth: 120 }} className="row-item thread-row">
                          Giá FOB lô trước (USD)
                        </div>
                        <div style={{ maxWidth: 120 }} className="row-item thread-row">
                          Duyệt giá FOB (USD)
                        </div>
                        <div style={{ maxWidth: 120 }} className="row-item thread-row">
                          Thành tiền (USD)
                        </div>
                      </>
                    ) : null}
                    <div style={{ maxWidth: 200 }} className="last-child thread-row">
                      Ghi chú mặt hàng
                    </div>
                  </div>

                  <div className="scroll-vertical-table">
                    {data.sale_order_items.map((item, index) => {
                      return (
                        <div key={index} className="row-wrapper">
                          <div className="row-item" style={{ maxWidth: 60 }}>
                            {index + 1}
                          </div>
                          <div className="row-item" style={{ minWidth: 250 }}>
                            {item.product.code || item.product.name
                              ? `${item.product.code}: ${item.product.name}`
                              : '-'}
                          </div>
                          <div style={{ maxWidth: 190 }} className="row-item">
                            {item.specification ? item.specification : '-'}
                          </div>
                          {isDrafting && (
                            <>
                              <div className="row-item" style={{ maxWidth: 120 }}>
                                {item.product_type === 'semifinish'
                                  ? item.first_price
                                    ? formatNumber(Number(item.first_price))
                                    : '-'
                                  : '-'}
                              </div>
                              <div className="row-item" style={{ maxWidth: 120 }}>
                                {item.product_type === 'finish'
                                  ? item.second_price
                                    ? formatNumber(Number(item.second_price))
                                    : '-'
                                  : '-'}
                              </div>
                            </>
                          )}
                          <div className="row-item" style={{ maxWidth: 120 }}>
                            {item.box_quantity ? formatNumber(item.box_quantity) : '-'}
                          </div>
                          <div className="row-item" style={{ maxWidth: 120 }}>
                            {item.total_quantity ? formatNumber(item.total_quantity) : '-'}
                          </div>
                          <div className="row-item" style={{ maxWidth: 150 }}>
                            {item.total_size ? formatNumber(item.total_size) : '-'}
                          </div>
                          {isPendingPIOrApprovedPIAndIsSuperAdmin ? (
                            <>
                              <div style={{ maxWidth: 120 }} className="row-item">
                                {item.old_fob ? formatNumber(item.old_fob) : ''}
                              </div>
                              <div style={{ maxWidth: 120 }} className="row-item">
                                {item.new_fob ? formatNumber(item.new_fob) : '-'}
                              </div>
                              <div style={{ maxWidth: 120 }} className="row-item">
                                {item.new_fob && item.box_quantity
                                  ? formatNumber(item.new_fob * item.box_quantity)
                                  : '-'}
                              </div>
                            </>
                          ) : null}
                          <div style={{ maxWidth: 200 }} className="last-child">
                            {item.note}
                          </div>
                        </div>
                      )
                    })}
                  </div>

                  <div className="row-wrapper">
                    <div className="row-item thread-row" style={{ maxWidth: 60 }} />
                    <div className="row-item thread-row" style={{ minWidth: 250 }} />
                    <div style={{ maxWidth: 190 }} className="row-item thread-row" />
                    {/* <div className="row-item thread-row">Nguyên vật liệu</div> */}
                    {isDrafting && (
                      <>
                        <div className="row-item thread-row" style={{ maxWidth: 120 }} />
                        <div className="row-item thread-row" style={{ maxWidth: 120 }} />
                      </>
                    )}
                    <div className="row-item thread-row" style={{ maxWidth: 120 }}>
                      {totalData.total_box_quantity
                        ? formatNumber(totalData.total_box_quantity)
                        : '-'}
                    </div>
                    <div className="row-item thread-row" style={{ maxWidth: 120 }}>
                      {totalData.total_quantity ? formatNumber(totalData.total_quantity) : '-'}
                    </div>
                    <div className="row-item thread-row" style={{ maxWidth: 150 }}>
                      {totalData.total_size ? formatNumber(totalData.total_size) : '-'}
                    </div>
                    {isPendingPIOrApprovedPIAndIsSuperAdmin ? (
                      <>
                        <div style={{ maxWidth: 120 }} className="row-item thread-row" />
                        <div style={{ maxWidth: 120 }} className="row-item thread-row" />
                        <div style={{ maxWidth: 120 }} className="row-item thread-row">
                          {totalData.total_money ? formatNumber(totalData.total_money) : '-'}
                        </div>
                      </>
                    ) : null}
                    <div style={{ maxWidth: 200 }} className="last-child thread-row" />
                  </div>
                </div>
              </div>
            </Col>
          </div>

          <div className="form-item">
            <div className="container-types">
              <Title className="order-table-title">Đóng hàng vào container</Title>
              <Col className="order-table-content">
                <div className="inner-content">
                  <div className="scroll-wrap">
                    <div className="row-wrapper">
                      <div className="row-item thread-row">Loại container</div>
                      <div className="row-item thread-row">Cubic (m3)</div>
                      <div className="last-child thread-row">Số lượng</div>
                    </div>

                    <div className="row-wrapper">
                      <div className="row-item">{`(1) Container 20'DC`}</div>
                      <div className="row-item">28.50 m3</div>
                      <div className="last-child">{data.container_type_1_amount}</div>
                    </div>

                    <div className="row-wrapper">
                      <div className="row-item">{`(2) Container 40'DC`}</div>
                      <div className="row-item">58.50 m3</div>
                      <div className="last-child">{data.container_type_2_amount}</div>
                    </div>

                    <div className="row-wrapper">
                      <div className="row-item">{`(3) Container 40'HC`}</div>
                      <div className="row-item">66.50 m3</div>
                      <div className="last-child">{data.container_type_3_amount}</div>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </div>

          <div className="form-item container-information" style={{ paddingBottom: 0 }}>
            <div className="item">
              <Title className="field-title">Tổng số khối:</Title>
              <span className="content">{totalCubic ? `${totalCubic} m3` : '- m3'}</span>
            </div>

            <div className="item">
              <Title className="field-title">Hiện tại:</Title>
              <span className="content">
                {presentCubic ? `${roundUpDecimal(presentCubic)} m3` : '- m3'}
              </span>
            </div>

            <div className="item">
              <Title className="field-title">Gợi ý:</Title>
              <span className="content">{generateSuggestion()}</span>
            </div>
          </div>

          {isApprovedPI && (
            <div className="form-item">
              <div className="container-types">
                <Title className="order-table-title">Đặt hàng NCC</Title>
                {size(data.suppliers) > 0 ? (
                  <Col className="order-table-content">
                    <div className="inner-content">
                      <div className="scroll-wrap" style={{ minWidth: 1150 }}>
                        <div className="row-wrapper">
                          <div className="row-item thread-row">Nhà Cung Cấp</div>
                          <div className="row-item thread-row">Đặt đơn hàng bao bì</div>
                          <div className="row-item thread-row">Đặt đơn hàng nguyên vật liệu</div>
                          <div className="row-item thread-row">Đặt đơn hàng tem</div>
                          <div className="last-child thread-row">Đặt đơn hàng thành phẩm</div>
                        </div>
                        <div style={{ maxHeight: 350, overflowY: 'scroll' }}>
                          <Suppliers
                            data={data}
                            viewId={viewId}
                            loadingCondition={loadingCondition}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                ) : (
                  <div style={{ marginLeft: 10, fontStyle: 'italic' }}>
                    Không còn nhà cung cấp nào cung cấp hàng hoá cho Order này.
                  </div>
                )}
              </div>
            </div>
          )}

          <DocumentsTable
            documentsFor="saleOrder"
            commodityExportId={data.commodity_export.id}
            saleOrderId={data.id}
            saleOrderStatus={data.status}
            orderNumber={data.order_number}
            exportNumber={data.commodity_export.export_number}
            customerCountry={data.customer.country}
          />

          <div className="other-documents">
            <OtherDocumentsTable documents={data.documents} action="view" />
          </div>

          <div className="button-container">
            <Button
              icon={<RollbackOutlined />}
              type="primary"
              loading={loadingCondition}
              className="cancel-button"
              onClick={handleBack}
            >
              Trở lại
            </Button>

            {isDrafting ? (
              <Button
                icon={<FormOutlined />}
                type="primary"
                loading={loadingCondition}
                className="process-button"
                onClick={handleEdit}
              >
                Chỉnh sửa
              </Button>
            ) : null}

            {isApprovedPI ? (
              <Button
                icon={<FormOutlined />}
                type="primary"
                loading={loadingCondition}
                className="process-button"
                onClick={handleEdit}
              >
                Sửa Proforma Invoice
              </Button>
            ) : null}

            {isPendingPI && isSuperAdmin ? (
              <Button
                icon={<FormOutlined />}
                type="primary"
                loading={loadingCondition}
                className="process-button"
                onClick={handlePI}
              >
                Sửa Proforma Invoice
              </Button>
            ) : null}

            {isDrafting && isSuperAdmin ? (
              <Button
                type="primary"
                htmlType="submit"
                className="pi-action"
                icon={<ToolOutlined />}
                loading={loadingCondition}
                onClick={() => updatePurchaseOrder('pending_pi')}
              >
                Tạo Proforma Invoice
              </Button>
            ) : null}

            {isPendingPI && isSuperAdmin ? (
              <Button
                type="primary"
                htmlType="submit"
                className="pi-action"
                icon={<ToolOutlined />}
                loading={loadingCondition}
                onClick={() => updatePurchaseOrder('approved_pi')}
              >
                Duyệt Proforma Invoice
              </Button>
            ) : null}

            {isApprovedPI && isSuperAdmin && data.qualify_to_produce ? (
              <Button
                type="primary"
                htmlType="submit"
                className="pi-action"
                icon={<ToolOutlined />}
                loading={loadingCondition}
                onClick={() => updatePurchaseOrder('producing')}
              >
                Tiến hành sản xuất
              </Button>
            ) : null}
          </div>
        </div>
      </div>
      <MaterialPriceModal
        {...editProductMaterial}
        setEditProductMaterial={setEditProductMaterial}
      />
    </div>
  )
})
