import React, { useEffect, useState } from 'react'
import { ErrorMessage, Field } from 'formik'
import { Typography, Select } from 'antd'
import _get from 'lodash/get'

import { removeVietnameseTones } from 'Utils'
import axios from 'Utils/axiosExpand'

import { CustomError } from './CustomComponent'

const { Title } = Typography
const { Option } = Select

const SaleOrderInPaper = ({
  selectedSaleOrder,
  oneSaleOrder,
  values,
  loadingCondition,
  setFieldValue,
}) => {
  const [state, setState] = useState({ loading: false, options: [] })

  useEffect(() => {
    if (values.customer_id) {
      getOrderNoOnPaperOptions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.customer_id])

  const onSelectOrderOnPaper = (val) => setFieldValue('sale_order_in_paper_id', val)

  const getOrderNoOnPaperOptions = async () => {
    const url = '/commodity_exports/list-orders-not-used-yet'
    if (!state.loading) setState({ loading: true, options: [] })

    try {
      const response = await axios.get(url, {
        params: {
          customer_id: values.customer_id,
          is_drafting: !!values.is_drafting,
          current_commodity_export_id: values.id,
        },
      })

      const options = _get(response, 'data', selectedSaleOrder)
      const isCurrentValueMatchOption = options.find(
        (ele) => ele.id === values.sale_order_in_paper_id
      )
      if (!isCurrentValueMatchOption) setFieldValue('sale_order_in_paper_id', null)

      setState({ loading: false, options })
    } catch (error) {
      setState({ loading: false, options: selectedSaleOrder })
    }
  }

  return (
    <>
      <div className="form-item" style={{ maxWidth: 400 }}>
        <Title className="field-title">
          Order No trên chứng từ <span className="compulsory-field-symbol">*</span>
        </Title>
        <Field name={`sale_order_in_paper_id`} disabled={loadingCondition}>
          {() => {
            return (
              <Select
                name={`sale_order_in_paper_id`}
                style={{ width: '100%' }}
                showSearch
                placeholder="Chọn order"
                value={values.sale_order_in_paper_id}
                onChange={onSelectOrderOnPaper}
                optionFilterProp="children"
                disabled={loadingCondition || state.loading}
                loading={loadingCondition || state.loading}
                getPopupContainer={(trigger) => trigger.parentNode}
                filterOption={(input, option) => {
                  const dataInput = removeVietnameseTones(input)
                  const dataOption = removeVietnameseTones(option.children)
                  return dataOption.toLowerCase().indexOf(dataInput.toLowerCase()) >= 0
                }}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              >
                {state.options.map((item, index) => (
                  <Option key={index} value={item.id} attr={item}>
                    {item.order_number || oneSaleOrder.find((x) => x.id === item.id).order_number}
                  </Option>
                ))}
              </Select>
            )
          }}
        </Field>

        <ErrorMessage
          component={(props) => <CustomError {...props} />}
          name={`sale_order_in_paper_id`}
        />
      </div>
    </>
  )
}

export default SaleOrderInPaper
